<div class="form-container">
    <form [formGroup]="searchFormGroup">
        <div class="row row-search-title">
            <div class="col mb-1">
                <h1 class="search-title" *ngIf="config.idTenant==4 && !hideH1 && !isOutlet && !isOpen && !showGratuityPassengerDropDown" [innerHTML]="'search.searchTitleRealRapido' | translate">Viagens de ônibus mais baratas e com segurança</h1>
                <h1 class="search-title" *ngIf="config.idTenant!=4 && !hideH1 && !isOutlet && !isOpen && !showGratuityPassengerDropDown" [innerHTML]="'search.searchTitle' | translate"></h1>
                <h1 class="search-title" *ngIf="config.idTenant==4 && !hideH1 && !isOutlet && showGratuityPassengerDropDown" [innerHTML]="'search.freeBookingSearchTitle' | translate">Viagens de ônibus mais baratas e com segurança</h1>
                <h1 class="search-title" *ngIf="config.idTenant!=4 && !hideH1 && !isOutlet && showGratuityPassengerDropDown" [innerHTML]="'search.freeBookingSearchTitle' | translate"></h1>
                <h1 class="search-title" *ngIf="config.idTenant==4 && !hideH1 && !isOutlet && isOpen" [innerHTML]="'search.voucherBookingSearchTitle' | translate">Viagens de ônibus mais baratas e com segurança</h1>
                <h1 class="search-title" *ngIf="config.idTenant!=4 && !hideH1 && !isOutlet && isOpen" [innerHTML]="'search.voucherBookingSearchTitle' | translate"></h1>
                <h1 class="search-title" *ngIf="config.idTenant==4 && !hideH1 && isOutlet" [innerHTML]="'search.outletSearchTitle' | translate">Viagens de ônibus mais baratas e com segurança</h1>
                <h1 class="search-title" *ngIf="config.idTenant!=4 && !hideH1 && isOutlet" [innerHTML]="'search.outletSearchTitle' | translate"></h1>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="search-fields">
                    <div class="search-field">
                        <label class="lbl">{{ 'search.originLabel' | translate }}</label>
                        <div class="input-with-icon">
                            <i class="fad fa-map-marker-alt input-icon"></i>
                            <input *ngIf="config.idTenant !== 2 || !runningOnMobile" id="typeahead-focus" type="text"
                                   class="input-location" placeholder="{{ 'search.originPlaceholder' | translate }}"
                                   [formControl]="departureLocationFormControl" #departureDropDown="ngbTypeahead"
                                   (click)="departureClick$.next($event.target.value)"
                                   [resultFormatter]="departureFormatter" typeahead-editable="false"
                                   (focus)="departureFocus$.next($event.target.value)"
                                   [inputFormatter]="departureFormatter"
                                   [ngbTypeahead]="searchDepartureLocations" />

                            <select *ngIf="config.idTenant === 2 && runningOnMobile" [formControl]="departureLocationFormControl">
                                <option hidden [value]="''" selected>
                                    {{ 'search.originPlaceholder' | translate }}
                                </option>
                                <option *ngFor="let departureSchedule of departureSchedules" [ngValue]="departureSchedule">
                                    {{ departureSchedule.DepartureLocationName }}
                                </option>
                            </select>
                        </div>
                        <button *ngIf="!isBookingCouponChange" [ngStyle]="{'background-color': browserHelper.convertToRgbA(secondaryColor, 1)}" (click)="invertLocations()" class="btn-change-direction">
                            <i class="fas fa-exchange-alt"></i>
                        </button>
                    </div>
                    <div class="search-field search-field-destination">
                        <label class="lbl">{{ 'search.destinationLabel' | translate }}</label>
                        <div class="input-with-icon">
                            <i class="fad fa-map-marker-alt input-icon"></i>
                            <input *ngIf="config.idTenant !== 2 || !runningOnMobile" id="typeahead-focus2" type="text"
                                   [ngClass]="useBorderLeft ? '' : 'no-border-left'"
                                   class="input-location"
                                   placeholder="{{ 'search.destinationPlaceholder' | translate }}"
                                   [resultFormatter]="arrivalFormatter"
                                   [inputFormatter]="arrivalFormatter" #arrivalDropDown="ngbTypeahead"
                                   (click)="arrivalClick$.next($event.target.value)" typeahead-editable="false"
                                   (focus)="arrivalFocus$.next($event.target.value)"
                                   [formControl]="arrivalLocationFormControl" [ngbTypeahead]="searchArrivalLocations" />

                            <select *ngIf="config.idTenant === 2 && runningOnMobile" [formControl]="arrivalLocationFormControl">
                                <option hidden [value]="''" selected>
                                    {{ 'search.destinationPlaceholder' | translate }}
                                </option>
                                <option *ngFor="let arrivalSchedule of arrivalSchedules" [ngValue]="arrivalSchedule">
                                    {{ arrivalSchedule.arrivalLocationName }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row search-field-dates">
                        <div class="col-md-6 search-field-departure-date">
                            <div class="search-field date-field" *ngIf="!isOpen">
                                <label class="lbl">{{ 'search.departureDateLabel' | translate }}</label>
                                <div class="input-with-icon input-date-search departure">
                                    <i style="left: 0px;" class="fad fa-calendar-alt input-icon"></i>
                                    <input *ngIf="config.idTenant !== 2 || !runningOnMobile" (click)="toggleDepartureDatepicker()" [value]="fromDate" placeholder="Data de ida">
                                    <app-custom-datepicker *ngIf="config.idTenant !== 2 || !runningOnMobile" class="departure"
                                                           (departureDateSelected)="onDepartureDateSelected($event)"
                                                           [minimumPriceRange]="minimumPriceRange"
                                                           (arrivalDateSelected)="onArrivalDateSelected($event)" #departureDate>
                                    </app-custom-datepicker>

                                    <input class="unstyled" placeholder="Data de ida" *ngIf="config.idTenant === 2 && runningOnMobile" type="date" [formControl]="departureDateFormControl">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 search-field-return-date">
                            <div class="search-field date-field" *ngIf="!isOpen">
                                <label class="lbl">{{ 'search.returnDateLabel' | translate }}</label>
                                <div class="input-with-icon input-date-search arrival">
                                    <i style="left: 0px;" class="fad fa-calendar-alt input-icon"></i>
                                    <input *ngIf="config.idTenant !== 2 || !runningOnMobile" [disabled]="isBookingCouponChange" (click)="toggleArrivalDatepicker()" [value]="toDate" placeholder="Data de volta" (keyup)="onBlurEvent($event)" [ngClass]="useBorderLeft ? '' : 'no-border-left'">
                                    <app-custom-datepicker *ngIf="config.idTenant !== 2 || !runningOnMobile" class="arrival"
                                                           [minDate]="getMinDate()" subtitle='volta' [minimumPriceRange]="minimumPriceRange"
                                                           (departureDateSelected)="onArrivalDateSelected($event)" #arrivalDate>
                                    </app-custom-datepicker>
                                    <input class="unstyled" placeholder="Data de volta" [min]="departureDateFormControl.value"
                                           *ngIf="config.idTenant === 2 && runningOnMobile" type="date"
                                           (keyup)="onBlurEvent($event)" [formControl]="arrivalDateFormControl">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="search-field" *ngIf="!showGratuityPassengerDropDown" >
                        <label class="lbl">{{ 'search.passenger' | translate }}</label>
                        <passenger-types-select (onSelectedCountChange)="passengersValue = $event.value" [value]="passengersQuery" ></passenger-types-select>
                    </div>
                    <div *ngIf="showDiscount && !showGratuityPassengerDropDown && !isOpen" class="search-field discount-field">
                        <label class="lbl">{{ 'search.discountLabel' | translate }}</label>
                        <div class="input-with-icon">
                            <i class="fad fa-tag input-icon"></i>
                            <input formControlName="discount" type="text" placeholder="Cupom de desconto" />
                        </div>
                    </div>
                    <div *ngIf="showGratuityPassengerDropDown" class="search-field gratuity-field">
                        <label class="lbl">Tipos de Gratuidade</label>
                        <select formControlName="gratuityPassengersDropDown">
                            <option *ngFor="let item of gratuityPassengersDropDownItems" value="{{item.key}}">
                                {{item.value}}
                            </option>
                        </select>
                    </div>
                    <div *ngIf="showPassengersDropdown" class="search-field passengers-field">
                        <label class="lbl">{{ 'search.passengerCountLabel' | translate }}</label>
                        <div class="input-with-icon">
                            <i class="fad fa-users input-icon"></i>
                            <select formControlName="seats" class="search-passenger-count" required>
                                <option disabled>{{ 'search.passengerCountLabel' | translate }}</option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                                <option>6</option>
                                <option>7</option>
                                <option>8</option>
                                <option>9</option>
                                <option>10</option>
                            </select>
                        </div>
                    </div>
                    <button (click)="getTrips()" [ngStyle]="{'background-color': secondaryColor}" class="btn-primary search-button button-home">
                        {{ searchButtonText | translate }}
                    </button>

                    <button (click)="getTrips()" [ngStyle]="{'background-color': secondaryColor}" class="btn-primary search-button button-header">
                        {{ 'search.changeSearchButtonChange' | translate }} <br class="only-above-mobile" />{{ 'search.changeSearchButtonSearch' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
